<template>
  <div class="main-wrapper">
    <!-- :style="`padding-top: ${user.verified || (!Object.keys(user).length && user !== 'ban') ? '97px' : '130px'}`" -->
    <div class="fixed-header">
      <Header v-if="user !== 'ban'" />
    </div>

    <router-view v-if="user !== 'ban'" />
    <div v-else>
      <Error
        class="allPage"
        :title="'Доступ заблокирован'"
        :description="'Вы несколько раз ввели неверный пароль. Доступ к порталу временно заблокирован. Пожалуйста, повторите попытку через 15 минут'"
      />
    </div>
    <div class="bottom-tip" v-if="user !== 'ban'">
      <div class="content" v-if="pageVerify && pageVerify.length">
        {{
          `Номер одобрения: ${pageVerify[0].number} Дата одобрения: ${this.$m(
            pageVerify[0].approval_date
          ).format("DD.MM.YYYY")}. Дата истечения: ${this.$m(
            pageVerify[0].expire_date
          ).format("DD.MM.YYYY")}`
        }}
      </div>
    </div>
    <Footer v-if="user !== 'ban'" />
  </div>
</template>

<script>
import Header from "../components/main/Header.vue";
import Footer from "../components/Footer.vue";
import Error from "@/views/Error.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { Header, Footer, Error },
  name: "main-layout",
  data: () => ({
    lastKnownScrollY: 0,
    currentScrollY: 0,
    ticking: false,
    eleHeader: null,
    headerContainer: null,
    classes: {
      pinned: "header-pin",
      unpinned: "header-unpin",
    },
  }),
  computed: {
    ...mapGetters(["user", "pageVerify", "Nosologys"]),
  },
  methods: {
    ...mapActions([
      "getSession",
      "fetchPageVerify",
      "fetchNosologys",
      "logout",
    ]),
    onScroll() {
      this.currentScrollY = window.pageYOffset;
      this.update();
    },
    update() {
      this.headerContainer = document.querySelector(".fixed-header");
      if(!this.headerContainer) return
      this.eleHeader = this.headerContainer.querySelector(".header");
      if (this.currentScrollY < this.lastKnownScrollY) {
        this.pin();
      } else if (
        this.currentScrollY > this.lastKnownScrollY &&
        this.currentScrollY > this.eleHeader.offsetHeight
      ) {
        this.unpin();
      }
      this.lastKnownScrollY = this.currentScrollY;
    },
    pin() {
      if (this.eleHeader.classList.contains(this.classes.unpinned)) {
        this.eleHeader.classList.remove(this.classes.unpinned);
        this.eleHeader.classList.add(this.classes.pinned);
        this.headerContainer.style.height = this.eleHeader.offsetHeight + "px";
      }
    },
    unpin() {
      if (
        this.eleHeader.classList.contains(this.classes.pinned) ||
        !this.eleHeader.classList.contains(this.classes.unpinned)
      ) {
        this.eleHeader.classList.remove(this.classes.pinned);
        this.eleHeader.classList.add(this.classes.unpinned);
      }
    },
  },
  async mounted() {
    await this.fetchPageVerify(this.$route.path);
    if (!Object.keys(this.Nosologys).length) {
      await this.fetchNosologys();
    }

    this.headerContainer = document.querySelector(".fixed-header");
    this.eleHeader = this.headerContainer.querySelector(".header");
    this.headerContainer.style.height = this.eleHeader.offsetHeight + "px";
    document.addEventListener("scroll", this.onScroll, false);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.onScroll, false);
  },
  watch: {
    async $route(to, from) {
      if (to.path !== from.path) {
        await this.fetchPageVerify(this.$route.path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed-header {
  @media screen and (min-width: 1221px) {
    height: 97px;
  }
  & .header {
    @media screen and (min-width: 1221px) {
      position: fixed;
      z-index: 10000;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: #fff;
      transform: translateY(0);
      transition: 0.3s;

      &-unpin {
        // transform: translateY(-100%);
        transform: translateY(0);
      }

      &-pin {
        transform: translateY(0);
      }
    }
  }
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media screen and (min-width: 1221px) {
    // padding-top: 130px;
  }
  @media screen and (max-width: 1220px) {
    padding-top: 0px !important;
  }
}

.bottom-tip {
  margin-top: 80px;
  margin-bottom: 24px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #b2b4b4;

  @media screen and (max-width: 1220px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
</style>